/**
 * Please notify us when you have significant changes in your system.
 * integration@fitanalytics.com
 **/

window._fitAnalyticsReportPurchase = function () {
  /**
   * basic shop context and configuration
   **/
  const shop = (window._fitAnalyticsReportPurchase.shop = {
    prefix: 'ms-',
    timers: {}
  });

  const lookup = (obj, path, defVal) => {
    obj = obj && obj instanceof Object ? obj : {};
    defVal == null && (defVal = null);
    path === '.' && (path = '');
    let v;
    let keys = Object.prototype.toString.apply(path) === '[object Array]' ? path : path.split('.');
    for (let i = 0, n = keys.length; i < n; i++) {
      v = obj[keys[i]];
      if (v != null) {
        obj = v;
      } else {
        return defVal;
      }
    }
    return v;
  };

  shop.getCookie = function (key) {
    const ca = document.cookie.split(';');
    key = key + '=';
    for (let i = 0, n = ca.length; i < n; i++) {
      const c = String(ca[i]).replace(/^\s+/, '');
      if (c.indexOf(key) === 0) {
        return decodeURIComponent(c.substring(key.length, c.length));
      }
    }
    return null;
  };

  shop.getData = function (path, def = '') {
    return lookup(window, 'fitAnalyticsData.' + path, def);
  };

  // normalize page's purchase data
  shop.getPurchase = function () {
    const purchase = {
      orderId: this.getData('orderId'),
      language: this.getData('shopLanguage'),
      country: this.getData('shopCountry'),
      currency: this.getData('currency'),
      userId: this.getData('userId'),
      products: this.getData('products', []),
      shopSessionId: shop.getCookie('_gid') || ''
    };

    return purchase;
  };

  /**
   * send the purchase to the backend
   **/
  shop.sendPurchase = function () {
    const purchase = this.getPurchase();

    for (let i = 0, l = purchase.products.length; i < l; ++i) {
      const product = purchase.products[i];

      const response = {
        userId: purchase.userId,
        orderId: purchase.orderId,
        currency: purchase.currency,
        language: purchase.language,
        shopCountry: purchase.country,
        shopSessionId: purchase.shopSessionId,
        ean: product.ean || '',
        price: product.price || '',
        quantity: product.quantity || '',
        purchasedSize: product.purchasedSize || '',
        shopArticleCode: product.shopArticleCode || '',
        productSerial:
          (product.productId && shop.prefix + product.productId?.split('_').shift()) || ''
      };

      window._sendPurchaseInformation(response);
      //   console.log(response);
    }
  };

  /**
   * loading
   **/
  // poll till we can send the purchase to the backend
  shop.timers.onPageLoaded = setInterval(function () {
    const purchase = shop.getPurchase();

    if (!purchase.products || !purchase.products.length) {
      return;
    } else {
      clearInterval(shop.timers.onPageLoaded);
    }

    shop.sendPurchase();
  }, 50);
};

(function () {
  const $script = document.createElement('script');
  $script.setAttribute('src', '//widget.fitanalytics.com/report_purchase.js');
  $script.setAttribute('type', 'text/javascript');
  $script.setAttribute('async', 'true');
  document.body.appendChild($script);
})();
